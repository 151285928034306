import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAtom } from 'jotai';
import { mainColorAtom } from '../Atoms';


const FAQ = () => {
    const [mainColor] = useAtom(mainColorAtom);
    const textColor = '#FFF';
  
    const faqData = [
      {
        question: 'Hoe kan ik de documentatie op deze website lezen?',
        answer:
          'Om de documentatie te lezen, kun je de kaarten aan de bovenkant van de home pagina gebruiken om naar het gewenste onderwerp te gaan. Klik op de secties om gedetailleerde informatie en handleidingen te vinden over het onderwerp.',
      },
      {
        question: 'Waar kan ik meer informatie vinden over de chatbots op deze website?',
        answer:
          'Voor meer informatie over de chatbot kun je naar de Cris en Oscar pagina navigeren. Hier vind je instructies over hoe je de chatbots kunt gebruiken en welke functionaliteiten ze bieden om je te helpen bij je werkzaamheden.',
      },
      {
        question: 'Hoe kan ik feedback geven op de documentatie?',
        answer:
          'Om feedback te geven over een specifiek onderwerp kunt u navigeren naar de juiste pagina. Aan het begin van de pagina ziet u een knop "Stuur Feedback".',
      },
      {
        question: 'Er klopt iets niet aan de pagina. Mag ik dat aanpassen?',
        answer:
          'Het is niet de bedoeling zelf dingen aan te passen. Als je denkt dat er iets niet klopt, kun je feedback geven via de knop "Stuur Feedback" aan het begin van de pagina. Als je denkt dat er iets ontbreekt, kun je ook feedback geven via de knop "Stuur Feedback". Ook kan je de auteur van de pagina een e-mail of Teams bericht sturen met je feedback.',
      },
      {
        question: 'Ik heb een bug ontdekt. Waar kan ik dat aangeven?',
        answer:
          'Een bug kan je melden via het Teams kanaal voor bugs. Ook kan je de IT afdeling bereiken via de Teams.',
      },
    ];
  
    return (
      <div>
        <Typography variant="h4" style={{ marginBottom: '20px' }}>
          Veelgestelde Vragen
        </Typography>
  
        {faqData.map((faq, index) => (
          <Accordion key={index} sx={{ backgroundColor: mainColor, color: textColor }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    );
  };

  export default FAQ;