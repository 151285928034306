import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar'; // Import Snackbar
import MuiAlert from '@mui/material/Alert'; // Import Alert for Snackbar
import Slide from '@mui/material/Slide'; // Import Slide for Snackbar
import FeedbackForm from './FeedbackForm';
import { useAtom } from 'jotai';
import { secondaryColorAtom } from '../Atoms';

const FeedbackButton = ({ pageName }) => {
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Manage Snackbar state
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [secondaryColor] = useAtom(secondaryColorAtom);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}
        sx={{
          color: secondaryColor,
          borderColor: secondaryColor
        }}
      >
        Stuur Feedback
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Stuur Feedback voor {pageName}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Schrijf uw feedback hier. Feedback sturen is anoniem.
          </DialogContentText>
          <FeedbackForm
            pageName={pageName}
            onClose={handleClose}
            showSnackbar={handleSnackbar} // Pass the snackbar handler
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000} // Adjust the duration (in milliseconds) as needed
        onClose={handleSnackbarClose}
        TransitionComponent={Slide} // Use Slide as the TransitionComponent
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default FeedbackButton;
