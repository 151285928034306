import RMAIcon from '../Icons/rmalogo2.png';

const iconWH = '35px';

const KnowledgeArticlesData = [
    { title: 'Definities', text: 'Lees de knowledge articles over definities', icon: <img src={RMAIcon} alt="Definities Icon" style={{ width: iconWH, height: iconWH }} />, link: '/Definities' },
    { title: 'Experiences', text: 'Lees de knowledge articles over experiences', icon: <img src={RMAIcon} alt="Experiences Icon" style={{ width: iconWH, height: iconWH }} />, link: '/Experiences' },
    { title: 'FAQ Intern', text: 'Lees de knowledge articles over FAQ intern', icon: <img src={RMAIcon} alt="FAQ intern Icon" style={{ width: iconWH, height: iconWH }} />, link: '/FAQ-intern' },
    { title: 'Industrie Reports', text: 'Lees de knowledge articles over industrie reports', icon: <img src={RMAIcon} alt="Industrie Reports Icon" style={{ width: iconWH, height: iconWH }} />, link: '/Industrie-Reports' },
    { title: 'Overige', text: 'Lees de knowledge articles over overige', icon: <img src={RMAIcon} alt="Overige Icon" style={{ width: iconWH, height: iconWH }} />, link: '/Overige' },
];

export default KnowledgeArticlesData;
