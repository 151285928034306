import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Breadcrumbs, Typography, Stack, Link } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const CustomBreadcrumbs = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(Boolean);

  // Check if on the home page
  const isHomePage = location.pathname === '/';

  const breadcrumbs = pathSegments.map((segment, index) => {
    const url = `/${pathSegments.slice(0, index + 1).join('/')}`;
    const isLastSegment = index === pathSegments.length - 1;

    // Modify segment text
    const modifiedSegment = segment.replace(/-/g, ' ').replace(/\b\w/g, (match) =>
      match.toUpperCase()
    );


    return (
      <Typography
        key={url}
        color={isLastSegment ? 'textPrimary' : 'inherit'}
        component={isLastSegment ? 'span' : RouterLink}
        underline="none" 
        to={url}
      >
        {modifiedSegment}
      </Typography>
    );
  });


  return (
    <Stack spacing={2} sx={{ marginTop: '16px', marginLeft: '16px' }}>
      {(isHomePage && <Typography color="textPrimary">Home</Typography>) || (
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link
            component={RouterLink}
            to="/"
            underline="none"
            color="inherit"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
          >
            Home
          </Link>
          {breadcrumbs}
        </Breadcrumbs>
      )}
    </Stack>
  );
};

export default CustomBreadcrumbs;
