import React from 'react';
import { CrisUrlAtom } from '../Atoms';
import './Chat.css';
import { IconButton, Typography } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useAtom } from 'jotai';
import { secondaryColorAtom } from '../Atoms';

const Chat = () => {
  const [CrisUrl] = useAtom(CrisUrlAtom);
  const [isOpen, setIsOpen] = React.useState(false);
  const [secondaryColor] = useAtom(secondaryColorAtom);

  const toggleChat = () => {
    setIsOpen((prev) => !prev);
  };

  const openUrl = () => {
    window
      .open(CrisUrl, '_blank')
      .focus();
  }


  return (
    <>
      <div className='chat-button'>
        <IconButton
          sx={{
            backgroundColor: secondaryColor,
            ":hover": {
              backgroundColor: '#4B4D62',
            },
            color: 'white',
            borderRadius: '20px',
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            padding: '10px',
            zIndex: 1000,
            //shadow on bottom
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.35)',
          }}
          onClick={toggleChat}
        >
          {!isOpen ? (
            <div>
              <ChatIcon /> 
              <Typography variant="h8">Chat met CRIS</Typography>
            </div>
          ): (<>
          </>
          )}
        </IconButton>
      </div>
      <div className={`chat-container-cris ${isOpen ? 'open' : 'closed'}`}>
      {isOpen && (
  
          
        <div className='iframe-cris'>
          <div>
            <IconButton
              sx={{
                //align to right
                position: 'absolute',
                top: 0,
                left: 0,
                ":hover": {
                  backgroundColor: '#4B4D62',
                },
              }}
              onClick={openUrl}
            >
              <OpenInNewIcon />
            </IconButton>
          </div>
          <iframe src={CrisUrl} title="CRIS" width="100%" height="100%" style={{ border: 'none', resize: 'both' }} />
        </div>

      )}
      </div>
    </>
  );
};


export default Chat;
