import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import axios from 'axios';

const FeedbackForm = ({ pageName, onClose, showSnackbar }) => {
  const [feedback, setFeedback] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleFeedbackChange = (event) => {
    const text = event.target.value;
    setFeedback(text);

    // Disable the button if the text length exceeds 2000 characters
    setButtonDisabled(text.length > 2000);
  };

  const handleFeedbackSubmit = async () => {
    // Disable the button and set loading state
    setButtonDisabled(true);
    setLoading(true);

    try {
      const feedbackData = {
        FeedbackText: feedback,
        PageName: pageName,
      };
      const functionCode = process.env.REACT_APP_FEEDBACK_CODE;
      const apiUrl = `https://feedbackdocusite.azurewebsites.net/api/SubmitFeedback?code=${functionCode}`;
      const response = await axios.post(apiUrl, JSON.stringify(feedbackData), {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        showSnackbar('Feedback submitted successfully', 'success');
      }
    } catch (error) {
      showSnackbar('Failed to submit feedback', 'error');
      console.error('Failed to submit feedback', error);
    } finally {
      // Reset the feedback field, enable the button, and close the dialog
      setFeedback('');
      setButtonDisabled(false);
      setLoading(false);
      onClose();
    }
  };

  return (
    <div>
      <TextField
        variant="outlined"
        fullWidth
        multiline
        rows={5}
        value={feedback}
        onChange={handleFeedbackChange}
        disabled={loading}
      />
      <div style={{ marginTop: '8px', marginBottom: '8px' }}>
        {feedback.length} / 2000
      </div>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleFeedbackSubmit}
        disabled={buttonDisabled || loading}
      >
        {loading ? 'Sending...' : 'Stuur Feedback'}
      </Button>
    </div>
  );
};

export default FeedbackForm;
