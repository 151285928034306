import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { tableOfContentsAtom } from '../Atoms';
import './OverviewContents.css'; // Import custom CSS for styling

const OverviewContents = () => {
  const [tableOfContents] = useAtom(tableOfContentsAtom);
  const [activeItem, setActiveItem] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      if (tableOfContents.length > 0) {
        const offsets = tableOfContents.map((item) => {
          const element = document.getElementById(item.id);
          return {
            id: item.id,
            offset: element ? element.getBoundingClientRect().top : 0,
          };
        });

        const visibleItem = offsets.reduce((a, b) => (
          Math.abs(a.offset) < Math.abs(b.offset) ? a : b
        ));

        setActiveItem(visibleItem.id);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [tableOfContents]);

  const handleItemClick = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });

      // Update the URL to include the section identifier
      window.history.replaceState(null, null, `#${id}`);
    }
  };

  return (
    <div
      style={{
        borderRadius: '12px',
        padding: '16px',
        boxShadow: '0px 10px 14px rgba(0, 0, 0, 0.25)',
        maxHeight: '70vh',
        overflowY: 'auto',  
      }}
    >
      <ul className="toc-list">
        {tableOfContents.map((item) => (
          <li
            key={item.id}
            className={`toc-item ${activeItem === item.id ? 'active' : ''}`}
            style={{ paddingLeft: item.level === 'h2' ? '20px' : '0px', fontSize: '18px' }}
          >
            <button
              onClick={() => handleItemClick(item.id)}
              style={{ fontSize: item.level === 'h2' ? '14px' : 'inherit' }}
            >{item.title}</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OverviewContents;
