import PowerBIIcon from '../Icons/powerbi_icon.png';
import RMAIcon from '../Icons/rmalogo2.png';

const iconWH = '35px';


const TrainingenData = [
    { title: 'Power BI', text: 'Bekijk hoe Power BI gebruikt wordt binnen RMA', icon: <img src={PowerBIIcon} alt="Power BI Icon" style={{ width: iconWH, height: iconWH }} />, link: '/power-bi' },
    { title: 'Tips en Tricks', text: 'Algemene handige dingen om te weten.', icon: <img src={RMAIcon} alt="Bugfixes Icon" style={{ width: iconWH, height: iconWH }} />, link: '/tips-en-tricks' },
];

export default TrainingenData;