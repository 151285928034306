import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Sidebar from './Sidebar';
import RMAIcon from '../Icons/rmalogo2.png';
import { useAtom } from 'jotai';
import { mainColorAtom, isAuthenticatedAtom, accountNameAtom, accountUserNameAtom, pinkmodeAtom, secondaryColorAtom } from '../Atoms';
import { alpha, styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import Switch from '@mui/material/Switch';
import { Button } from '@mui/material';
import hampterImg from './hampter.png';

const PinkSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: pink[600],
    '&:hover': {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: pink[600],
  },
}));

const label = { inputProps: { 'aria-label': 'Color switch demo' } };

export default function SearchAppBar() {
  const [isAuthenticated] = useAtom(isAuthenticatedAtom);
  const [secondaryColor] = useAtom(secondaryColorAtom);
  const [mainColor, setMainColor] = useAtom(mainColorAtom);
  const [accountName] = useAtom(accountNameAtom);
  const [accountUserName] = useAtom(accountUserNameAtom);
  const [pinkmode, setPinkmode] = useAtom(pinkmodeAtom);
  const [switchVisible, setSwitchVisible] = React.useState(false);

  // Load pinkmode state from localStorage on component mount
  React.useEffect(() => {
    const cachedPinkmode = localStorage.getItem('pinkmode');
    if (cachedPinkmode !== null) {
      setPinkmode(cachedPinkmode === 'true');
    }
  }, []);

  // Update pinkmode state in localStorage whenever it changes
  React.useEffect(() => {
    localStorage.setItem('pinkmode', pinkmode);
  }, [pinkmode]);

  React.useEffect(() => {
    // Update main color based on pink mode state
    if (pinkmode) {
      setMainColor('#FFB6C1 ');
    } else {
      setMainColor('#4B4D62');
    }
  }, [pinkmode]);

  React.useEffect(() => {
    if (accountUserName !== null && (accountUserName.toLowerCase() === 'seyed@rma.nl' || accountUserName.toLowerCase() === 'sophie.vergalen@rma.nl' || accountUserName.toLowerCase() === 'geiske.vanlogtestijn@rma.nl' || accountUserName.toLowerCase() === 'kiki.jacobs@rma.nl')) {
      setSwitchVisible(true);
    }
  }, [accountUserName]);
  
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: mainColor }} >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {isAuthenticated && <Sidebar />}
            <a href="/">
              <img
                src={RMAIcon}
                alt="RMA Logo"
                style={{ width: '100%', maxWidth: '48px' }}
              />
            </a>
            <Typography
              variant="h7"
              noWrap
              component="div"
              sx={{
                display: 'flex',
                alignItems: 'center',
                '& a': {
                  textDecoration: 'none',
                  color: 'white',
                  marginLeft: '16px',
                },
              }}
            >
              <a href="/">RMA Documentatie</a>
            </Typography>
            {isAuthenticated && <div style={{ marginLeft: '100px' }}>
            </div>}
          </div>
          <Button
                variant="contained"
                href="/Oscar"
                style={{ marginRight: '12px' }}
                sx={{ backgroundColor: secondaryColor }}
                target="_blank"
                rel="noopener noreferrer"
              >
                Chat met Oscar
              </Button>
          <div style={{ position: 'relative' }}>
    {pinkmode && (
        <img src={hampterImg} alt="Hampster" style={{ width: '38px', position: 'absolute', zIndex: '0', marginLeft: '-50px' }} />
    )}
    { switchVisible && (
        <div>
            <PinkSwitch {...label} checked={pinkmode} onChange={() => setPinkmode(!pinkmode)} />
        </div>
    )}
</div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{marginRight: '20px' }}>
                  {accountName}
                  <br/>
                  {accountUserName}
                </div>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
