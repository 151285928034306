import RMAIcon from '../Icons/rmalogo2.png';

const iconWH = '35px';

const HRDocumentenData = [
    {
        subTabs: true,
    },
    {
        title: 'Personeelsgids',
        text: 'Lees meer over de personeelsgids van RMA',
        icon: <img src={RMAIcon} alt="Personeelsgids Icon" style={{ width: iconWH, height: iconWH }} />,
        items: [
            { title: 'Personeelsgids',  icon: <img src={RMAIcon} alt="Personeelsgids Icon" style={{ width: iconWH, height: iconWH }} />, link: '/personeelsgids' },
        ],
    },
    {
        title: 'Junior Consultant',
        text: 'Bekijk documentatie voor Junior Consultants',
        icon: <img src={RMAIcon} alt="Junior Consultant Icon" style={{ width: iconWH, height: iconWH }} />,
        items: [
            { title: 'Financieel', icon: <img src={RMAIcon} alt="Junior Consultant Icon" style={{ width: iconWH, height: iconWH }} />, link: '/junior-consultant-financieel' },
            { title: 'Juridisch', icon: <img src={RMAIcon} alt="Junior Consultant Icon" style={{ width: iconWH, height: iconWH }} />, link: '/junior-consultant-juridisch' },
            { title: 'Fiscaal', icon: <img src={RMAIcon} alt="Junior Consultant Icon" style={{ width: iconWH, height: iconWH }} />, link: '/junior-consultant-fiscaal' },
            { title: 'Office', icon: <img src={RMAIcon} alt="Junior Consultant Icon" style={{ width: iconWH, height: iconWH }} />, link: '/junior-consultant-office' },
            { title: 'Overig', icon: <img src={RMAIcon} alt="Junior Consultant Icon" style={{ width: iconWH, height: iconWH }} />, link: '/junior-consultant-overig' },
        ],
    },
    {
        title: 'Consultant',
        text: 'Bekijk documentatie voor Consultants',
        icon: <img src={RMAIcon} alt="Consultant Icon" style={{ width: iconWH, height: iconWH }} />,
        items: [
            { title: 'Juridisch', icon: <img src={RMAIcon} alt="Junior Consultant Icon" style={{ width: iconWH, height: iconWH }} />, link: '/consultant-juridisch' },
            { title: 'Fiscaal', icon: <img src={RMAIcon} alt="Junior Consultant Icon" style={{ width: iconWH, height: iconWH }} />, link: '/consultant-fiscaal' },
            { title: 'Office', icon: <img src={RMAIcon} alt="Junior Consultant Icon" style={{ width: iconWH, height: iconWH }} />, link: '/consultant-office' },
        ],
    },
    {
        title: 'Senior Consultant / Manager',
        text: 'Bekijk documentatie voor Senior Consultants / Managers',
        icon: <img src={RMAIcon} alt="Senior Consultant Icon" style={{ width: iconWH, height: iconWH }} />,
        items: [
            { title: 'Juridisch', icon: <img src={RMAIcon} alt="Junior Consultant Icon" style={{ width: iconWH, height: iconWH }} />, link: '/senior-consultant-juridisch' },
            { title: 'Fiscaal', icon: <img src={RMAIcon} alt="Junior Consultant Icon" style={{ width: iconWH, height: iconWH }} />, link: '/senior-consultant-fiscaal' },
        ],
    },
    {
        title: 'Online Kennissessies',
        text: 'Bekijk online kennissessies',
        icon: <img src={RMAIcon} alt="Online Kennissessies Icon" style={{ width: iconWH, height: iconWH }} />,
        items: [
            { title: 'Kennissessie 1', icon: <img src={RMAIcon} alt="Junior Consultant Icon" style={{ width: iconWH, height: iconWH }} />,link: '/kennissessie-1' },
            { title: 'Kennissessie 2', icon: <img src={RMAIcon} alt="Junior Consultant Icon" style={{ width: iconWH, height: iconWH }} />, link: '/kennissessie-2' },
            { title: 'Kennissessie 3', icon: <img src={RMAIcon} alt="Junior Consultant Icon" style={{ width: iconWH, height: iconWH }} />, link: '/kennissessie-3' },
            { title: 'Kennissessie 4', icon: <img src={RMAIcon} alt="Junior Consultant Icon" style={{ width: iconWH, height: iconWH }} />,link: '/kennissessie-4' },
        ],
    },
];

export default HRDocumentenData;
