import React, { useState, useEffect } from 'react';
import { Typography, Tab, Tabs } from '@mui/material';
import { useAtom } from 'jotai';
import { mainColorAtom, accountNameAtom } from '../Atoms';
import CardInfo from '../Components/CardInfo';
import Grid from '@mui/material/Grid';
import RMALogo from '../Icons/rma_logo.png';
import './Home.css';
import ITHandleidingenData from '../Data/ITHandleidingenData';
import UniformeWerkwijzeData from '../Data/UniformeWerkwijzeData';
import TrainingenData from '../Data/TrainingenData';
import FAQ from './faq';
import KnowledgeArticlesData from '../Data/KnowledgeArticlesData';
import HRDocumentenData from '../Data/HRDocumentenData';
import SubTabs from './SubTabs';

const tabLabels = [
  "IT Handleidingen",
  "Uniforme Werkwijze",
  "Trainingen",
  "Knowledge articles",
  "HR - Opleidingen",
];

const tabContentData = [
  ITHandleidingenData,
  UniformeWerkwijzeData,
  TrainingenData,
  KnowledgeArticlesData,
  HRDocumentenData,
];

const Home = () => {
  const [accountName] = useAtom(accountNameAtom);
  const [currentTab, setCurrentTab] = useState(0);
  const [mainColor] = useAtom(mainColorAtom);

  useEffect(() => {
    const savedTab = localStorage.getItem('currentTab');
    if (savedTab !== null) {
      setCurrentTab(Number(savedTab));
    }
  }, []);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
    localStorage.setItem('currentTab', newValue);
  };

  return (
    <div className="container">
      <Grid container justifyContent="center">
        <Grid item>
          <div className="logo-container">
            <img src={RMALogo} alt="RMA Logo" style={{ width: '100%', maxWidth: '800px' }} />
          </div>
        </Grid>
      </Grid>
      <div className="profile-content">
        <Typography variant="h4">
          Welkom, {accountName}!
        </Typography>
        <br />
        <Typography variant="h6">
          Klik op een van de onderstaande kaarten om meer te weten te komen over de verschillende onderwerpen van Rembrandt Fusies en Overnames.
        </Typography>
      </div>
      <Tabs 
        TabIndicatorProps={{
          style: { backgroundColor: mainColor },
        }}
        value={currentTab} 
        onChange={handleTabChange}
      >
        {tabLabels.map((label, index) => (
          <Tab
            key={index}
            label={<Typography sx={{ color: currentTab === index ? mainColor : 'inherit' }}>{label}</Typography>}
          />
        ))}
      </Tabs>
      <br/>
      <div className="tab-content">
          {tabContentData[currentTab].length > 0 ? (
      tabContentData[currentTab][0].subTabs ? (
        <>
          <SubTabs subTabsData={tabContentData[currentTab]}/>
        </>
      ) : (
        <div className="tab-panel">
          {tabContentData[currentTab].map((card, index) => (
            <CardInfo key={index} {...card} />
          ))}
        </div>
      )
    ) : (
      <div className="tab-panel">
        <Typography> Nothing to see here </Typography>
      </div>
    )}
    </div>
      <br/>
      <br/>
      <div className="text-container">
        <Typography variant="a">
          Deze website is bedoeld om een uitgebreide bron van informatie en documentatie te bieden aan de medewerkers van Rembrandt Fusies en Overnames. Wat kun je verwachten op deze website? We hebben een schat aan documentatie samengesteld die je helpt de verschillende aspecten van onze software en bedrijfsvoering te begrijpen.
          <br />
          <br />
          We hebben ook een chatbot (CRIS) ontwikkeld die je kan helpen bij het vinden van informatie over de verschillende onderwerpen die op deze website worden behandeld. De chatbot is rechtsonder op de pagina te vinden.
        </Typography>
      </div>
      <br />
      <FAQ />
      <br />
      <br />
    </div>
  );
};

export default Home;
