import React, { useState } from 'react';
import { Tabs, Tab, Typography } from '@mui/material';
import CardInfo from '../Components/CardInfo';
import './Home.css';

const SubTabs = ({ subTabsData }) => {
    const [currentSubTab, setCurrentSubTab] = useState(0);

    const handleSubTabChange = (event, newValue) => {
        setCurrentSubTab(newValue);
    };

    return (
        <div>
            <div className="subtabs">
            <Tabs
                value={currentSubTab}
                onChange={handleSubTabChange}
                indicatorColor="primary"
                textColor="primary"
            >
                {subTabsData.map((tab, index) => (
                    tab.title ? ( // Check if the tab has a title before rendering it
                        <Tab key={index} label={tab.title} />
                    ) : null
                ))}
            </Tabs>
            </div>
            <br/>
            <br/>
            <div className="tab-panel">
                {subTabsData[currentSubTab + 1]?.items?.map((card, index) => (
                    <CardInfo key={index} {...card} />
                )) || (
                    <Typography>Nothing to display</Typography> // Fallback if there are no items
                )}
            </div>
        </div>
    );
};

export default SubTabs;
