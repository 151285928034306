import { atom } from 'jotai';

export const mainColorAtom = atom('#4B4D62');
export const secondaryColorAtom = atom('#FF8C00');

export const tableOfContentsAtom = atom([]);

export const loadingPageAtom = atom(false);

export const messageHistoryAtom = atom([]);

export const saveSuccessAtom = atom(false);

export const accessTokenAtom = atom(null);

export const chatStateAtom = atom(false);

export const CrisUrlAtom = atom('https://cris-web-rma.azurewebsites.net/');
// export const CrisUrlAtom = atom('http://127.0.0.1:5000/');

export const graphAccessTokenAtom = atom(null);

export const isAuthenticatedAtom = atom(false);

export const accountNameAtom = atom(null);

export const accountUserNameAtom = atom(null);

export const pinkmodeAtom = atom(false);

export const softwareTeamAtom = atom(["seyed@rma.nl", "ruben.vanbodegraven@rma.nl", "sean.taylor@rma.nl", "abdellah@rma.nl", "roderick.kranendonk@rma.nl"]);

export const uwwTeamAtom = atom(["geiske.vanlogtestijn@rma.nl", "sophie.vergalen@rma.nl", "kiki.jacobs@rma.nl"]);

export const allowedToEditAtom = atom(false);