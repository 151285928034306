import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import popupblockedImg from "./popupblocked.png";
import allowpopupImg from "./allowpopup.png";
import { PropagateLoader } from "react-spinners";

const StepByStep = () => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      style={{ height: "100vh", backgroundColor: "#f0f0f0" }}
    >
      <Grid item>
        <Typography variant="h4" align="center">
          De authorizatie popup is geblokkeerd. 
        </Typography>
        <br/>
        <Typography variant="h5" align="center">
            Volg de onderstaande stappen om de popup te deblokkeren.
        </Typography>
        <br/><br/>
      </Grid>
      <Grid item>
        <Timeline>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Typography variant="body1">Klik hier</Typography>
              <img src={popupblockedImg} alt="Edge Settings" style={{ width: '200px' }} />
              <div style={{ width: '300px' }} />

            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Typography variant="body1">Geef toestemming voor de Pop Up om in te kunnen loggen.</Typography>
              <img src={allowpopupImg} alt="Site instellingen" style={{ width: '300px' }} />
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot />
            </TimelineSeparator>
            <TimelineContent>
              <Typography variant="body1">Refresh de pagina.</Typography>
              <div style={{ width: '300px' }} />

            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </Grid>
    </Grid>
  );
};


const LoggedOut = () => {
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    isLoading ? (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        style={{ height: "100vh", backgroundColor: "#f0f0f0" }}
      >
        <Grid item>
          <Typography variant="h4" align="center">
            RMA Docs is aan het laden 
          </Typography>
        <br/>
        <br/>
          <PropagateLoader color="#EBA013" />
        </Grid>
      </Grid>
    ) : (
      <StepByStep />
    )
  );
};


export default LoggedOut;
