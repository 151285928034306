import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useAtom } from 'jotai';
import { mainColorAtom, secondaryColorAtom } from '../Atoms';

export default function CardInfo({ title, text, icon, link, additionalInfo }) {
  const [isHovered, setIsHovered] = React.useState(false);
  const [mainColor] = useAtom(mainColorAtom);
  const [secondaryColor] = useAtom(secondaryColorAtom);

  return (
    <>
      {title && <>
        <Link href={link} rel="noopener noreferrer" underline="none">
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              '& > :not(style)': {
                m: 1,
                width: 350,
                height: 128,
              },
            }}
          >
            <Paper
              elevation={isHovered ? 10 : 6} // Increase elevation on hover
              sx={{
                backgroundColor: mainColor,
                transition: 'background-color 0.17s ease-in-out',
                '&:hover': {
                  backgroundColor: secondaryColor, // Orange color on hover
                },
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  height: '100%',
                  padding: '16px',
                }}
              >
                <Box sx={{ marginRight: '16px' }}>{icon}</Box>
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                  <Typography variant="h6" component="h3" gutterBottom sx={{ color: 'white', marginBottom: '8px' }}>
                    {title}
                  </Typography>
                  <Typography variant="body1" sx={{ flexGrow: 1, color: 'white' }}>
                    {text}
                  </Typography>
                  {isHovered && additionalInfo && (
                    <div style={{ marginTop: '16px' }}>
                      {additionalInfo}
                    </div>
                  )}
                </div>

              </Box>
            </Paper>
          </Box>
        </Link>
      </>}
    </>
  );
}


