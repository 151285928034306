import PowerpointIcon from '../Icons/powerpoint_icon.png';
import ExcelIcon from '../Icons/excel_icon.png';
import WordIcon from '../Icons/word_icon.png';
import OutlookIcon from '../Icons/outlook_icon.png';
import RMAIcon from '../Icons/rmalogo2.png';
import ChatBotIcon from '../Icons/crisoscar.png';
const iconWH = '35px';


const ITHandleidingenData = [
    { title: 'Outlook Web', text: 'De nieuwe Outlook Add in voor Outlook Web', icon: <img src={OutlookIcon} alt="Outlook Web Icon" style={{ width: iconWH, height: iconWH }} />, link: '/outlook-web-Add-in' },
    { title: 'Excel', text: 'Lees meer over de Excel Add-in', icon: <img src={ExcelIcon} alt="Excel Icon" style={{ width: iconWH, height: iconWH }} />, link: '/excel-Add-in' },
    { title: 'Word', text: 'Lees meer over de Word Add-in', icon: <img src={WordIcon} alt="Word Icon" style={{ width: iconWH, height: iconWH }} />, link: '/word-Add-in' },
    { title: 'PowerPoint', text: 'Leer meer over de PowerPoint Add-in', icon: <img src={PowerpointIcon} alt="PowerPoint Icon" style={{ width: iconWH, height: iconWH }} />, link: '/powerpoint-Add-in' },
    { title: 'IT Algemeen', text: 'Ontdek informatie en documentatie over IT', icon: <img src={RMAIcon} alt="IT Icon" style={{ width: iconWH, height: iconWH }} />, link: '/it-algemeen' },
    { title: 'Urenapplicatie', text: 'Lees meer over de urenapplicatie van RMA', icon: <img src={RMAIcon} alt="Urenapplicatie Icon" style={{ width: iconWH, height: iconWH }} />, link: '/urenapplicatie' },
    { title: 'Cris en Oscar', text: 'Lees meer over de chatbot van RMA', icon: <img src={ChatBotIcon} alt="Chatbot Icon" style={{ width: iconWH, height: iconWH }} />, link: '/cris-en-oscar' },
    { title: 'IT Updates', text: 'Lees meer over de IT updates van RMA', icon: <img src={RMAIcon} alt="IT Updates Icon" style={{ width: iconWH, height: iconWH }} />, link: '/it-updates' },
    { title: 'Digitaal Ondertekenen', text: 'Lees meer over digitaal ondertekenen', icon: <img src={RMAIcon} alt="CM Sign Icon" style={{ width: iconWH, height: iconWH }} />, link: '/cm-sign' },
    { title: 'Beta Testing', text: 'Lees meer over de Beta Testing', icon: <img src={RMAIcon} alt="Beta Testing Icon" style={{ width: iconWH, height: iconWH }} />, link: '/beta-testing' },
];

export default ITHandleidingenData;

// {/* Overig*/},