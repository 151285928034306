import React from 'react';
import './App.css';
import AuthenticatedApp from '../Layout/AuthenticatedApp';
import { ChatProvider } from '../Components/ChatContext';
import Auth from './Auth';
import {useAtom} from 'jotai';
import {isAuthenticatedAtom} from '../Atoms';
import LoggedOut from '../Layout/LoggedOut';

/**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */

/**
 * If a user is authenticated, the ProfileContent component above is rendered. Otherwise, a message indicating a user is not authenticated is rendered.
 */
const MainContent = () => {
  const [isAuthenticated] = useAtom(isAuthenticatedAtom);
  return (
    <div className="App">
      {isAuthenticated ? ( <>
          <AuthenticatedApp />
        </> ) 
        : 
        ( 
        <>
          <LoggedOut />
        </> 
        )
      }
    </div>
  );
};

function App() {
  return (
    <React.StrictMode>
        <ChatProvider>
          <Auth />

      <MainContent />
        </ChatProvider>
    </React.StrictMode>
  );
}

export default App;
