import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { useAtom } from 'jotai';
import { saveSuccessAtom } from '../Atoms';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { TreeView, TreeItem } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import axios from 'axios';

function Sidebar({ data }) {
  const [treeData, setTreeData] = useState([]);

  useEffect(() => {
    if (data) {
      const parsedData = data.map(item => ({
        ...item,
        tableOfContents: JSON.parse(item.tableOfContents || '[]'), // Parse the tableOfContents string
      }));
      setTreeData(parsedData);
    }
  }, [data]);

  const handleItemClick = (topLevelItemId, sectionId) => {
    // Replace spaces with hyphens and perform the redirection
    const url = `/${topLevelItemId.replace(/\s+/g, '-')}`;
    if (sectionId) {
      window.location.href = `${url}#${sectionId}`;
    } else {
      window.location.href = url;
    }
  };
  
  const handleTreeItemClick = (event, topLevelItemId, sectionId) => {
    // Check if the click target is not the expand/collapse icon
    if (!event.target.classList.contains('MuiSvgIcon-root')) {
      // Handle click only when clicking on the label
      handleItemClick(topLevelItemId, sectionId);
    }
  };
  
  const renderTree = (nodes, topLevelItemId) => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id}
      label={nodes.title}
      onClick={(event) => handleTreeItemClick(event, topLevelItemId, nodes.id)}
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node, topLevelItemId))
        : null}
    </TreeItem>
  );

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {treeData.map((item) => (
        <TreeItem
          key={item.title}
          nodeId={item.title}
          label={<Typography variant="h7">{item.title}</Typography>}
          onClick={(event) => handleTreeItemClick(event, item.title)}
        >
          {item.tableOfContents.map((node) => renderTree(node, item.title))}
        </TreeItem>
      ))}
    </TreeView>
  );
}

export default function TemporaryDrawer() {
  const [saveSuccess] = useAtom(saveSuccessAtom);
  const [contentData, setContentData] = useState([]);

  useEffect(() => {
    const handleContentFetch = async () => {
        try {
            const functionCodeContent = process.env.REACT_APP_CONTENT_CODE;
            const apiUrl = `https://docucontentfunction.azurewebsites.net/api/ContentFunction?code=${functionCodeContent}`;
            const response = await axios.get(apiUrl);

            if (response.status === 200) {
                const contentDataRes = response.data;
                setContentData(contentDataRes);
            }
        } catch (error) {
            console.error("Failed to fetch content", error);
        } 
    };

    handleContentFetch();
  }, [saveSuccess]);


  const [state, setState] = useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box 
  sx={{
    width: 280,
  }}
  role="presentation"
  onClick={toggleDrawer(anchor, true)}
  onKeyDown={toggleDrawer(anchor, true)}
>

      <Button onClick={toggleDrawer(anchor, true)}>
        <IconButton
          size="large"
          edge="start"
          aria-label="open drawer"
          sx={{ mr: 2 }}
        >
          <MenuIcon color="white" />
        </IconButton>
      </Button>
      <Sidebar data={contentData} />;
      
    </Box>
  );

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            <IconButton
              size="large"
              edge="start"
              aria-label="open drawer"
              sx={{ mr: 2 }}
            >
              <MenuIcon color="white" />
            </IconButton>
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
