import React from "react";
import { Typography, Link } from "@mui/material";
import Grid from "@mui/material/Grid";
import RMALogo from "../Icons/rma_logo2.png";
import { useAtom } from 'jotai';
import { mainColorAtom } from '../Atoms';

const Footer = () => {
  const [mainColor] = useAtom(mainColorAtom);
  return (
    <div style={{ backgroundColor: mainColor, padding: "100px", margin: "0px 0" }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={4}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={RMALogo} alt="RMA Logo" style={{ maxWidth: "80%", height: "auto" }} />
          </div>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h5" color="white" align="center" sx={{ mb: 2 }}>
            Over Rembrandt Fusies en Overnames
          </Typography>
          <Typography variant="body1" color="white" align="center">
            Rembrandt Fusies en Overnames is een toonaangevend adviesbureau dat gespecialiseerd is in fusies en overnames. Onze missie is om uitzonderlijke adviesdiensten aan onze klanten te bieden en hen te helpen bij het bereiken van hun zakelijke doelen.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h5" color="white" sx={{ mb: 2 }}>
            Handige Links
          </Typography>
          <Typography variant="body1" color="white" sx={{ mb: 1 }}>
            <Link href="https://www.rma.nl/en/" color="inherit" underline="none">Rembrandt site</Link>
          </Typography>
          <Typography variant="body1" color="white" sx={{ mb: 1 }}>
            <Link href="https://rembrandtfo.sharepoint.com/sites/RFO/Documents/Forms/AllItems.aspx" color="inherit" underline="none">SharePoint</Link>
          </Typography>
          <Typography variant="body1" color="white" sx={{ mb: 1 }}>
            <Link href="https://rfo.crm4.dynamics.com/main.aspx?forceUCI=1&pagetype=apps" color="inherit" underline="none">Dynamics</Link>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;






